<template>
  <section class="section-skew black-gradient-header">
    <div class="position-fixed w-100 send-back">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-md-6 seller-image" :style="getSellerBackground"></div>
          <div class="col-md-6 h-100vh white-background"></div>
        </div>
      </div>
    </div>
    <div
      v-if="partner"
      class="container pt-8 position-relative bring-to-front-5"
    >
      <div class="row">
        <div class="col-md-5 offset-md-7">
          <div v-if="!paidForSubscription">
            <h1 class="text-lg font-weight-900 text-black text-center">
              👉 Join Bujin.tv - {{ partner.name }} community 👈
            </h1>

            <!-- plans section -->
            <div class="mb-sm">
              <div class="step_heading d-flex align-items-center">
                <button
                  type="button"
                  class="btn rounded-circle btn-icon-only btn-icon btn-primary"
                >
                  1
                </button>

                <h4 class="mb-0">Select a plan</h4>
              </div>

              <card class="mt-3 border border-primary">
                <div
                  @click="togglePlans"
                  class="badge badge-circle badge-darker float-right cursor-pointer toggle-plan"
                >
                  <i
                    v-if="showPlans"
                    class="fa fa-chevron-up text-white toggle-plan"
                  ></i
                  ><i
                    v-else
                    class="fa fa-chevron-down text-white toggle-plan"
                  ></i>
                </div>

                <slide-up-down :active="showPlans">
                  <div>
                    <div
                      v-for="(plan, index) in partnerSubscriptionPlans"
                      :key="index"
                      @click="selectPlan(plan)"
                      class="cursor-pointer"
                    >
                      <hr
                        v-if="index > 0"
                        class="border-primary w-100 mx-0 my-3"
                      />
                      <span
                        v-if="plan.featured"
                        size="sm"
                        v-b-popover.hover.left="plan.description"
                        title="Why is the best value?"
                        class="mb-2 btn btn-tooltip btn-danger btn-sm"
                        >Best Value
                      </span>
                      <strong>{{ plan.trial_days }} Days Free Access</strong>
                      <br />

                      <span
                        class="mr-1 text-muted text-through"
                        id="standard_price"
                      >
                        {{ subscriptionPlans[index].price.formatted }}</span
                      >
                      <strong>
                        <span id="plan_price">{{
                          plan.price.formatted
                        }}</span></strong
                      >
                      <span class="small">
                        Billed {{ plan.interval }} (cancel anytime - no
                        fee)</span
                      >
                    </div>
                  </div>
                </slide-up-down>

                <slide-up-down :active="selectedPlan && !showPlans">
                  <!-- selected plan -->
                  <div v-if="selectedPlan">
                    <span
                      v-if="selectedPlan.featured"
                      size="sm"
                      v-b-popover.hover.left="selectedPlan.description"
                      title="Why is the best value?"
                      class="mb-2 btn btn-tooltip btn-danger btn-sm"
                      >Best Value
                    </span>
                    <strong
                      >{{ selectedPlan.trial_days }} Days Free Access</strong
                    >
                    <br />
                    <strong
                      v-bind:class="{ 'text-void': promocodeValidation.active }"
                    >
                      <span id="selected_plan_price">{{
                        selectedPlan.price.formatted
                      }}</span>
                    </strong>
                    <strong
                      v-if="promocodeValidation.active"
                      v-bind:class="{
                        'text-primary': promocodeValidation.active,
                      }"
                    >
                      <span v-if="promocodeValidation.coupon.amount_off">
                        ${{ promoPrice() }}
                      </span>
                      <span v-if="promocodeValidation.coupon.percent_off">
                        ${{ promoPricePercent() }}
                      </span>
                    </strong>
                    <span class="small">
                      Billed {{ selectedPlan.interval }} (cancel anytime - no
                      fee)</span
                    >
                  </div>
                </slide-up-down>
                <slide-up-down :active="selectedPlan && !showPlans">
                  <hr class="border-bottom-dashed w-100 mx-0 my-3" />
                  <div class="promocode small">
                    <span class="small"><strong>Enter Promo Code</strong></span>
                    <input
                      type="text"
                      v-model="promocode"
                      class="small mr-2 ml-2 border-bottom-dashed"
                    />
                    <button
                      id="promocode"
                      @click="sendPromocode()"
                      type="button"
                      class="btn btn-primary btn-sm small float-right"
                    >
                      Apply
                    </button>
                    <span
                      class="small"
                      v-bind:class="{
                        'text-danger': !promocodeValidation.active,
                        'text-primary': promocodeValidation.active,
                      }"
                      >{{ promocodeValidation.coupon.name }}
                      <template v-if="promocodeValidation.coupon.amount_off"
                        >${{
                          promocodeValidation.coupon.amount_off / 100
                        }}</template
                      ></span
                    >
                  </div>
                </slide-up-down>
              </card>
            </div>

            <!-- create user section -->
            <div class="mb-sm">
              <div class="step_heading d-flex align-items-center">
                <button
                  type="button"
                  class="btn rounded-circle btn-icon-only btn-icon btn-outline-primary"
                >
                  2
                </button>
                <h4 class="mb-0">Create an account</h4>
              </div>
              <card class="mt-3 border border-primary">
                <form v-if="!user" role="form" @submit.prevent="register">
                  <base-input
                    id="account-name"
                    alternative
                    class="mb-3"
                    v-model="newUserData.name"
                    :error="errorFor('name')"
                    placeholder="Name"
                    prepend-icon="ni ni-hat-3"
                  >
                  </base-input>
                  <base-input
                    id="account-email"
                    alternative
                    type="email"
                    class="mb-3"
                    v-model="newUserData.email"
                    :error="errorFor('email')"
                    placeholder="Email"
                    prepend-icon="ni ni-email-83"
                  >
                  </base-input>
                  <base-input
                    id="account-password"
                    alternative
                    type="password"
                    v-model="newUserData.password"
                    :error="errorFor('password')"
                    placeholder="Password"
                    prepend-icon="ni ni-lock-circle-open"
                  >
                  </base-input>

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      id="create_account"
                      class="my-4"
                      :disabled="registeringUser"
                    >
                      Create account
                    </base-button>
                  </div>
                  <div class="text-center small disclaimer">
                    Your personal information will be kept strictly
                    confidential.
                    <router-link :to="{ name: 'privacy' }" class="text-blue"
                      >Learn More
                    </router-link>
                  </div>
                </form>

                <div v-else>
                  <span>{{ user.name }} </span>
                  <br />
                  <strong>{{ user.email }}</strong>
                </div>
              </card>
            </div>

            <!-- payment section -->
            <div>
              <div class="step_heading d-flex align-items-center">
                <button
                  type="button"
                  class="btn rounded-circle btn-icon-only btn-icon btn-outline-primary"
                >
                  3
                </button>
                <h4 class="mb-0">Add your payment method</h4>
              </div>
              <card class="mt-3 border border-primary">
                <p class="mb-2 font-weight-900">Payment method</p>

                <div ref="card" id="cardelement"></div>

                <base-button
                  id="pay"
                  type="primary"
                  class="my-4"
                  :disabled="!canProcessSubscription"
                  @click="processSubscription"
                >
                  Process Subscription
                </base-button>
                <div class="disclaimer text-center small">
                  We do NOT store submitted credit card information on our
                  servers. Your personal information will be kept strictly
                  confidential
                </div>
                <span
                  v-if="paymentErrors"
                  id="payment_error"
                  class="d-block text-danger invalid-feedback payment_error"
                  >{{ paymentErrors }}</span
                >
              </card>
            </div>
          </div>

          <div v-else class="text-center">
            <logo variation="black_full"></logo>

            <img
              src="~@/assets/img/youkoso.svg"
              :style="{ maxWidth: '350px' }"
              alt="Thanks for subscribing"
              class="img-fluid"
              id="welcome-image"
              :data-price="selectedPlan.price.formatted"
              :data-currency="selectedPlan.price.currency"
            />

            <p class="text-lg text-sm-xl text-center lh-130 font-weight-400">
              Welcome to Bujin.tv!<br />
              The first video streaming platform <br />
              for martial arts is waiting for you!
            </p>
            <p class="text-lg text-sm-xl text-center lh-130 font-weight-400">
              <strong>Download our App</strong>
            </p>
            <div id="app-ios" class="text-center">
              <div class="text-center">
                <a
                  id="app-ios"
                  href="https://apps.apple.com/us/app/bujin-tv/id1557275634"
                >
                  <img
                    src="~@/assets/img/download_ios.png"
                    alt="Download Bujin.tv on App Store"
                    :style="{ width: '150px' }"
                  />
                </a>
              </div>
              <div class="text-center mt-2 mb-2">
                <a
                  id="app-android"
                  href="https://play.google.com/store/apps/details?id=com.trespixels.bujintv"
                >
                  <img
                    src="~@/assets/img/download_google.png"
                    alt="Download Bujin.tv on App Store"
                    :style="{ width: '180px' }"
                  />
                </a>
              </div>
              <p class="text-lg text-sm-xl text-center lh-130 font-weight-400">
                - Or -
              </p>
              <base-button
                tag="router-link"
                :to="{ name: 'main' }"
                class="text-lg font-weight-800 mb-sm"
                type="primary"
              >
                Continue to Bujin.tv 🥋
              </base-button>
            </div>

            <div class="d-flex align-items-center justify-content-center">
              <span class="mr-1">Follow us</span>
              <a
                href="https://www.instagram.com/bujintv"
                class="mr-1"
                target="_blank"
              >
                <img
                  src="~@/assets/img/instagram_icon_black.svg"
                  alt="Bujin.tv Instagram"
                  :style="{ width: '40px' }"
                />
              </a>
              <a href="https://www.facebook.com/bujintv" target="_blank">
                <img
                  src="~@/assets/img/facebook_icon_black.svg"
                  alt="Bujin.tv Facebook"
                  :style="{ width: '40px' }"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import handlesApiErrors from "@/mixins/handlesApiErrors";
import handlesStripePayments from "@/mixins/handlesStripePayments";
import handlesUserAccess from "@/mixins/handlesUserAccess";
import Logo from "@/components/Logo";
import SlideUpDown from "vue-slide-up-down";
import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import axios from "axios";
import Partner from "@/api/Partner";

export default {
  name: "subscribePartner",
  props: {
    partnerSlug: {
      type: String,
      default: "",
    },
  },
  mixins: [handlesApiErrors, handlesStripePayments, handlesUserAccess],
  components: { Logo, SlideUpDown },
  directives: {
    BPopover: VBPopover,
    VBTooltip,
  },
  data() {
    return {
      selectedPlan: null,
      showPlans: false,
      newUserData: {
        name: "",
        email: "",
        password: "",
        partnerId: "",
      },
      partner: null,
      promocode: "",
      promocodeValidation: {
        id: null,
        active: false,
        coupon: {
          id: null,
          name: "",
        },
      },
      registeringUser: false,
      isProcessingSubscription: false,
    };
  },
  async created() {
    Partner.$find(this.partnerSlug)
      .then((partner) => {
        this.partner = partner;
        this.$store.dispatch("fetchSubscriptionPlans");
        this.$store.dispatch("fetchPartnerSubscriptionPlansById", {
          id: partner.id,
        });
      })
      .then(() => {
        console.log("3PX: then", this.$refs.card);
        this.mountCard(this.$refs.card);
      });
  },

  computed: {
    ...mapState(["subscriptionPlans", "partnerSubscriptionPlans"]),
    ...mapGetters(["user"]),

    getSellerBackground() {
      console.log(
        "3PX: background: ",
        this.partner ? this.partner.background_img : "No background"
      );
      return {
        background:
          "url('" +
          (this.partner ? this.partner.background_img : "No_background.jpg") +
          "') no-repeat top center / cover",
        // backgroundSize: "contain",
      };
    },
    billingDetails() {
      if (!this.user) return {};

      return {
        name: this.user.name,
        email: this.user.email,
      };
    },
    canProcessSubscription() {
      return this.selectedPlan && this.user && !this.isProcessingSubscription;
    },
  },
  methods: {
    togglePlans() {
      this.showPlans = !this.showPlans;
    },
    sendPromocode() {
      axios
        .post("/stripe/promocode", { promocode: this.promocode })
        .then((response) => {
          this.promocodeValidation = response.data;
          // console.log(this.promocodeValidation);
        });
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
      this.togglePlans();
    },
    async register() {
      this.clearApiErrors();
      this.newUserData.partnerId = this.partnerId;
      this.klaviyoIdentify(this.newUserData.email, this.newUserData.name);
      this.klaviyoCreateAccount();
      this.registeringUser = true;
      try {
        await this.$store.dispatch("register", this.newUserData);
      } catch (e) {
        this.handleApiErrors(e);
      }
      this.registeringUser = false;
    },
    promoPrice() {
      const price =
        (this.selectedPlan.price.amount -
          this.promocodeValidation.coupon.amount_off) /
        100;
      return price.toFixed(2);
    },
    promoPricePercent() {
      const price =
        (this.selectedPlan.price.amount *
          (1 - this.promocodeValidation.coupon.percent_off / 100)) /
        100;
      return price.toFixed(2);
    },
    async processSubscription() {
      if (!this.canProcessSubscription) return;

      this.isProcessingSubscription = true;

      let setupIntent, confirmedSetupIntent, paymentMethodId;
      try {
        setupIntent = await this.getSetupIntent();
        confirmedSetupIntent = await this.confirmCardSetup(setupIntent);
        paymentMethodId = confirmedSetupIntent.payment_method;
      } catch (e) {
        this.displayPaymentError(e);
        return;
      }

      try {
        await this.subscribeToPlan(
          this.getGatewayPlanId(this.selectedPlan),
          paymentMethodId,
          this.promocodeValidation.id
        );
        this.klaviyoPaymentSuccess();
      } catch (e) {
        this.displayPaymentError(e);
        return;
      }

      // wait for 5 seconds to let the backend sync the subscription info
      await new Promise((resolve) => setTimeout(resolve, 5000));
      // get fresh data to give access to the user
      await this.$store.dispatch("fetchProfile");

      this.isProcessingSubscription = false;
    },
    displayPaymentError(error) {
      this.klaviyoPaymentError();
      error =
        error.response && error.response.data ? error.response.data : error;

      this.isProcessingSubscription = false;
      this.paymentErrors = error.message;
    },
  },
  watch: {
    partnerSubscriptionPlans: {
      immediate: true,

      handler() {
        // console.log("3PX: partner", );
        // let fromQueryParam = this.subscriptionPlans.find(
        //   (p) => p.id == this.$route.query.plan
        // );

        let selectedPlan = this.partnerSubscriptionPlans.find(
          (p) => p.featured === true
        );
        if (selectedPlan) this.selectedPlan = selectedPlan;
      },
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: none;
}

.disclaimer {
  color: gray;
}
</style>
